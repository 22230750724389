const courseCertExpiration = ({ id }) => {
	return courseExpirationMap.find((course) => course.id === id);
};

export default courseCertExpiration;

const courseExpirationMap = [
	{
		id: "64f5f2a8173a093c5e011563",
		name: "GWO: BST",
		years: 2,
	},
	{
		id: "64f5f2d2173a093c5e011564",
		name: "GWO: BTT",
		years: null,
	},
	{
		id: "650193625f3889d0b049f437",
		name: "GWO: ART",
		years: 2,
	},
	{
		id: "650196275f3889d0b049f438",
		name: "GWO: Blade Repair",
		years: null,
	},
	{
		id: "650208aada6e06f82e917833",
		name: "Rescue I End User",
		years: 1,
	},
	{
		id: "6509b1df5db2bdc9237bd505",
		name: "Train the Trainer",
		years: 1,
	},
	{
		id: "6509b35d33a52edde189aac9",
		name: "Train the Trainer SRK Only",
		years: 1,
	},
	{
		id: "6509b4b433a52edde189aaca",
		name: "Competent Climber & Authorized Rescuer",
		years: 1,
	},
	{
		id: "6509b50884dbc8db1200d126",
		name: "GWO: BSTR",
		years: 1,
	},
	{
		id: "6509b56b8a1320a1d9dd22cb",
		name: "Rescue I End User Recert",
		years: 1,
	},
	{
		id: "6509b5c650d75df6b2c25455",
		name: "Train the Trainer Recert",
		years: 1,
	},
	{
		id: "6509b6252af1e0b5f0113dfb",
		name: "Train the Trainer SRK Only Recert",
		years: 1,
	},
	{
		id: "6509b6bb20494e2d3bb91dee",
		name: "SRK Training",
		years: 1,
	},
	{
		id: "6509ba07588a7fcb2556713c",
		name: "Confined Space Entry",
		years: 1,
	},
	{
		id: "6509ba2a588a7fcb2556713d",
		name: "OnTrack Train the Trainer Recert",
		years: 1,
	},
	{
		id: "6509ba2c588a7fcb2556713e",
		name: "OnTrack Train the Trainer",
		years: 1,
	},
	{
		id: "6509babd58ce01216e1b7836",
		name: "Fall Protection Awareness",
		years: 1,
	},
	{
		id: "6509bb8ea02eed928fe19d98",
		name: "GWO: Slinger Signaller",
		years: null,
	},
	{
		id: "6509bbb8681174851f4da2da",
		name: "Annual Evaluator",
		years: 1,
	},
	{
		id: "6509bd0a008fbff015f8a35a",
		name: "Annual Evaluator Recert",
		years: 1,
	},
	{
		id: "6509bd21008fbff015f8a35b",
		name: "Automatic Descent",
		years: 1,
	},
	{
		id: "6509be030d45540f4d2aff85",
		name: "Controlled Descent",
		years: 1,
	},
	{
		id: "6509be22022104caaa6eeacb",
		name: "Controlled Descent Recert",
		years: 1,
	},
	{
		id: "6509be6afc076bbecce6242d",
		name: "Elevated Emergency Management",
		years: 1,
	},
	{
		id: "6509e0798350abde1706b752",
		name: "Inspection Training",
		years: null,
	},
	{
		id: "6509e473863034a9620246de",
		name: "AHA CPR/ First Aid/ AED Training",
		years: 2,
	},
	{
		id: "65319fa699a47e523b2c45bd",
		name: "ARK Training",
		years: 1,
	},
	{
		id: "657318eee7f5216ab48ba5da",
		name: "BEK Training",
		years: 1,
	},
	{
		id: "659839bbbed8dd9d55718b9a",
		name: "TTT for Licensing Agreement",
		years: 1,
	},
	{
		id: "65be9ea7395cb80f0796a453",
		name: "TUA Test Template",
		years: null,
	},
	{
		id: "65ce930fe47c2ba523a83dab",
		name: "Demo",
		years: null,
	},
	{
		id: "66198bc2ac370bc1d9f582a7",
		name: "Train the Trainer: HEK",
		years: 1,
	},
	{
		id: "661fd31be6bf15393acc5870",
		name: "GWO EFA",
		years: 2,
	},
	{
		id: "661fd541faab40d66d0220e4",
		name: "Client Equipment Training",
		years: 1,
	},
	{
		id: "661fd7696adb1b39935c6815",
		name: "Competent Climber/ Authorized Rescuer + EEM",
		years: 1,
	},
	{
		id: "661fd8a03d3ac93a1a4d885c",
		name: "Competent Climber/ Authorized Rescuer + EEM Recert",
		years: 1,
	},
	{
		id: "66475f395671671c0617ad4d",
		name: "GWO FA",
		years: 2,
	},
	{
		id: "66475f50916413deb7696955",
		name: "GWO WAH/MH",
		years: 2,
	},
	{
		id: "66475f68b99803e2cb25c1b0",
		name: "GWO FAW",
		years: 2,
	},
	{
		id: "66475f815ab6aaba5d70b0d0",
		name: "GWO FAR",
		years: 2,
	},
	{
		id: "66475f915ab6aaba5d70b0d1",
		name: "GWO WAHR/MHR",
		years: 2,
	},
	{
		id: "66475fa751bb4b5f0c291f77",
		name: "GWO FAWR",
		years: 2,
	},
	{
		id: "664cb7e22e29578b270eefb6",
		name: "GWO BSTR/ARTR",
		years: 2,
	},
	{
		id: "65be9ea7395cb80f0796a453",
		name: "TUA Test Template",
		years: null,
	},
	{
		id: "665f3efef67c48d5e592dc7c",
		name: "Site Visit",
		years: null,
	},
	{
		id: "6675d19dfbff22aff2590af5",
		name: "GWO MH",
		years: 2,
	},
	{
		id: "6688244be19e6c437740263b",
		name: "GWO CoHE",
		years: 2,
	},
	{
		id: "66a3a1a0a13d280db608a408",
		name: "GWO IQT-X",
		years: null,
	},
];
